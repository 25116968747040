<template>
  <v-layout wrap v-if="content" class="link-external">
    <v-flex xs12>
      <translate
        v-model="content.LinkExternalName"
        :languages="languages"
        :label="$t('dtouch.linkExternal.name', locale)"
        @input="handleChangeIsModified"
        style="padding: 0 10px 0 10px;"
      />
    </v-flex>
    <v-flex xs12>
      <translate
        v-model="content.LinkExternal"
        :languages="languages"
        :label="$t('dtouch.linkExternal.url', locale)"
        @input="handleChangeIsModified"
        style="padding: 10px 10px 0 10px;"
      />
    </v-flex>
    <v-flex xs12>
      <v-checkbox
        v-model="content.LinkExternalNewTab"
        :value="content.LinkExternalNewTab"
        @change="handleChangeIsModified"
        :label="$t('dtouch.linkExternal.newTab', locale)"
      />
    </v-flex>
  </v-layout>
</template>
<script>
  import { mapState } from 'vuex'
  import Translate from '@/components/Translate'
  export default {
    name: 'Form32LinkExternal',
    components: {
      Translate
    },
    props: {
      editedItem: {
        type: Object,
        required: true
      },
      onChangeIsModified: {
        type: Function,
        required: true,
      },
      config: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      content: null,
    }),
    computed: {        
      ...mapState('app', ['locale']),
      languages () {
        return this.config.Languages
      },
    },
    watch: {
      editedItem () {
        this.prepareContent()
      },
    },
    mounted () {
      this.prepareContent()
    },
    methods: {
      checkJSON (v) {
        return typeof v == 'object' ? v : JSON.parse(v)
      },
      prepareContent () {
        const aux = this.editedItem
        aux.LinkExternalName = this.checkJSON(aux.LinkExternalName)
        aux.LinkExternal = this.checkJSON(aux.LinkExternal)
        this.content = aux
      },
      handleChangeIsModified () {
        this.onChangeIsModified(this.content) 
      },
    },
  }
</script>
<style scoped>
.link-external .flex {
}
</style>

